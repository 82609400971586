<template>
  <div
    id="dashboard-view"
  >
    <n-scrollbar>
      <div class="greetings-section">
        <span>Welcome!</span>
      </div>
      <OrganizationChart
        :id="$store.state.selectedOwningOrg.id"
      />
    </n-scrollbar>
  </div>
</template>

<script>
import OrganizationChart from '@/components/shared/charts/OrganizationChart'

export default {
  name: 'Dashboard',
  components: {
    OrganizationChart
  },
  props: {
  },
  methods: {
  },
}
</script>

<style>

  .greetings-section {
    padding: 45px 20px 20px 20px;
    font-size: 2.5em;
    font-weight: bold;
  }

</style>
